import Konva from 'konva';
import { Mapping } from '../consts/mapping';
import { SettingTypesConsts } from '../consts/settingTypes';
import { AdditionalProductInfo } from '../model/additionalProductInfo';
import { GeneralSettings } from '../model/generalSettings';
import { Product } from '../model/product';
import { Setting } from '../model/setting';
import { Wall } from '../model/wall';
import { CollisionDetection } from './collisonDetection';
import { DesignUtils } from './designUtils';
import { FictionalSettingsUtil } from './fictionalSettingsUtil';
import { SettingMapUtils } from './settingMapUtil';
import { SubProductsUtil } from './subproductsUtil';

export class ProductUtils {
  static createNewProduct(
    product: Product,
    activeLayer: Konva.Layer,
    settings: GeneralSettings
  ): void {
    const newProduct = new Product(
      { ...product.attrs, strokeWidth: 20 },
      product.type,
      product.handlePlacement,
      product.isBottom,
      product.settings,
      product.isFictional,
      product.isVisibleInDrawing,
      product.canBeSubproduct,
      product.additionalInfo,
      product.subcategoryID,
      product.productID,
      product.graphicFileID,
      product.design
    );

    newProduct.materialMinHeight = product.materialMinHeight;
    newProduct.materialMinWidth = product.materialMinWidth;
    newProduct.productSettings = product.productSettings;

    product.settings.forEach((s: Setting) => {
      if (s.previousValue === null) {
        s.previousValue = s.value;
      }
    });

    newProduct.fixedWidth = product.fixedWidth;
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();

    const wallX = product.attrs.x;
    const wallY = product.attrs.y;
    let wallW = 0;
    let wallH = 0;
    let wallS = 0;

    const productHeight = settings.settings.find((setting: Setting) => {
      return setting.id === 150;
    });

    activeLayer.find('.wall').each((x: Wall) => {
      wallW = x.attrs.width;
      wallH = x.attrs.height;
      wallS = x.sockelHeight;
    });

    newProduct.sockel = wallS;

    // depends on product type (top or bottom align)
    newProduct.attrs.x = wallX;
    if (newProduct.isBottom === true) {
      if (newProduct.productSettings.TOTAL_FRONT_HEIGHT) {
        newProduct.attrs.y =
          wallH - Number(newProduct.productSettings.TOTAL_FRONT_HEIGHT) - wallS;
      } else if (newProduct.productSettings.HEIGHT) {
        newProduct.attrs.y =
          wallH - Number(newProduct.productSettings.HEIGHT) - wallS;
      } else if (newProduct.productSettings.CABIN_HEIGHT) {
        newProduct.attrs.y =
          wallH - Number(newProduct.productSettings.CABIN_HEIGHT) - wallS;
      } else {
        newProduct.attrs.y = wallH - 100 - wallS;
      }
    } else if (newProduct.isBottom === false) {
      // we need general setting
      if (productHeight) {
        newProduct.attrs.y = wallH - Number(productHeight.value);
      } else {
        newProduct.attrs.y = wallH - 2000;
      }
    } else {
      newProduct.attrs.y = 100;
    }

    if (newProduct.isBottom !== null) {
      for (let i = wallX; i < wallW; i++) {
        CollisionDetection.checkCollision(activeLayer, newProduct, null);
        if (!newProduct.isValid) {
          newProduct.attrs.x = i;
        }
      }
    }

    if (!newProduct.isValid) {
      newProduct.attrs.x = 0;
      newProduct.attrs.y = 100;
    }

    if (isNaN(newProduct.attrs.x)) {
      newProduct.attrs.x = 0;
    }
    if (isNaN(newProduct.attrs.y)) {
      newProduct.attrs.y = 100;
    }

    activeLayer.add(newProduct);
    // activeLayer.draw();
  }

  static restoreProduct(
    product: Product,
    activeLayer: Konva.Layer,
    fixedWidth: boolean
  ) {
    if (!activeLayer) {
      return;
    }
    const newProduct = new Product(
      { ...product.attrs },
      product.type,
      product.handlePlacement,
      product.isBottom,
      product.settings,
      product.isFictional,
      product.isVisibleInDrawing,
      product.canBeSubproduct,
      product.additionalInfo,
      product.subcategoryID,
      product.productID,
      product.graphicFileID,
      product.design
    );

    newProduct.materialMinHeight = product.materialMinHeight;
    newProduct.materialMinWidth = product.materialMinWidth;

    const wallX = product.attrs.x;
    const wallY = product.attrs.y;
    let wallW = 0;
    let wallH = 0;

    activeLayer.find('.wall').each((x) => {
      wallW = x.attrs.width;
      wallH = x.attrs.height;
    });

    newProduct.fixedWidth = fixedWidth;
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();

    // depends on product type (top or bottom align)

    // why it was here

    // for (let i = wallX; i < wallW; i++) {
    //   CollisionDetection.checkCollision(activeLayer, newProduct)
    //   if (!newProduct.isValid) {
    //     newProduct.attrs.x = i;
    //   }
    // }

    activeLayer.add(newProduct);
    // activeLayer.draw();
  }


  static createProductFromData(data: any, designs): Product {
    let isBottom = null;
    let handlePlacement = 'BOTTOM';

    const settings = SettingMapUtils.mapSettings(
      data.productSettingValues,
      data.edgeValue
    );
    if (data.productMountingType) {
      if (data.productMountingType === 'BENCH') {
        isBottom = true;
      }
    } else if (data.benchOrWall) {

      isBottom = data.benchOrWall.benchOrWallID === 2 ? true : false;
    }


    if (isBottom) {
      handlePlacement = 'TOP';
    }

    const additionalInfo: AdditionalProductInfo = {
      edge: data.edge,
      material: data.material,
      color: data.color,
      handle: data.handle,
    };



    let graphicFile;
    if (data.files) {
      graphicFile = data.files.find(
        (file: any) => file.contentType === 'PRODUCT_ORDER_IMG'
      );
    }
    let graphicFileID = null;
    if (graphicFile) {
      graphicFileID = graphicFile.fileID;
    }



    const newProduct = new Product(
      { x: 0, y: 0, width: data.widthMin, height: data.heightMin },
      data.name,
      handlePlacement,
      isBottom,
      settings,
      data.isFictional,
      data.visibleInDrawing,
      data.canBeSubproduct,
      additionalInfo,
      data.subCategory.subCategoryID,
      data.productID,
      graphicFileID,
      designs
    );

    if (newProduct.isBottom) {
      newProduct.settings.push(
        FictionalSettingsUtil.getFictionalSockelSetting()
      );
    } else {
      newProduct.settings.push(
        FictionalSettingsUtil.getFictionalDekorlistSetting()
      );
    }
    newProduct.settings.push(
      FictionalSettingsUtil.getFictionalNewCabinSetting()
    );

    this.updateDesign(newProduct, designs);

    newProduct.materialMinHeight = data.materialMinHeight;
    newProduct.materialMinWidth = data.materialMinWidth;

    // newProduct.fixedWidth = product.fixedWidth;
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();

    return newProduct;
  }

  static createProductFromOrder(data: any, designs): Product {
    let isBottom = false;
    let handlePlacement = 'BOTTOM';

    const settings = SettingMapUtils.mapSettings(
      data.product?.productSettingValues,
      data.edgeValue
    );

    // update editable
    if (!settings) {
      return null;
    }


    if (data.product.productMountingType) {
      if (data.product.productMountingType === 'BENCH') {
        isBottom = true;
        handlePlacement = 'TOP';
      }
    } else if (
      data.product.benchOrWall &&
      data.product.benchOrWall.benchOrWallID === 2
    ) {

      isBottom = true;
      handlePlacement = 'TOP';
    }
    settings.forEach((setting) => {
      if (!data.productOrderSettings) {
        return;
      }
      const value = data.productOrderSettings.find(
        (oset) => oset.settingID === setting.id
      );
      if (value != null) {
        if (value.modified && setting.type === SettingTypesConsts.CALCULATED) {
          setting.isFrozen = true;
        }
        setting.value = value.value;
        setting.previousValue = value.value;
        setting.readonly = value.readonly;
        setting.override = value.override;
        setting.modified = value.modified;
        setting.previousValue = value.previousValue;
      }
    });

    const additionalInfo: AdditionalProductInfo = {
      edge: data.edge,
      material: data.material,
      color: data.color,
      handle: data.handle,
    };



    let graphicFile;
    if (data.product && data.product.files) {
      graphicFile = data.product.files.find(
        (file: any) => file.contentType === 'PRODUCT_ORDER_IMG'
      );
    }
    let graphicFileID = null;
    if (graphicFile) {
      graphicFileID = graphicFile.fileID;
    }

    const newProduct = new Product(
      {
        x: data.positionX,
        y: data.positionY,
        width: data.product.widthMin,
        height: data.product.heightMin,
      },
      data.product.name,
      handlePlacement,
      isBottom,
      settings,
      data.product.isFictional,
      data.product.visibleInDrawing,
      data.product.subproduct,
      additionalInfo,
      data.product.subCategory.subCategoryID,
      data.product.productID,
      graphicFileID,
      null
    );

    newProduct.materialMinHeight = data.materialMinHeight;
    newProduct.materialMinWidth = data.materialMinWidth;

    // Add when dekorlist functionality is ready

    if (newProduct.isBottom) {
      newProduct.settings.push(
        FictionalSettingsUtil.getFictionalSockelSetting()
      );
    } else {
      newProduct.settings.push(
        FictionalSettingsUtil.getFictionalDekorlistSetting()
      );
    }
    const fictionalSettings = JSON.parse(data.fictionalSettings);
    if (fictionalSettings?.dekorlist) {
      newProduct.changeValue('HAS_DEKORLIST', fictionalSettings.dekorlist);
    }
    if (fictionalSettings?.sockel) {
      newProduct.changeValue('HAS_SOCKEL', fictionalSettings.sockel);
    }
    if (fictionalSettings?.newCabin) {
      newProduct.changeValue('NEW_CABIN', fictionalSettings.newCabin);
    }

    ProductUtils.updateDesign(newProduct, designs);
    DesignUtils.updateDesign(newProduct, true);
    newProduct.updateDimensions();

    return newProduct;
  }

  static restoreProductFromData(data, layer: Konva.Layer) {
    // is it used?

    data.settings = data.settings.map((setting: Setting) => {
      return new Setting(
        setting.id,
        setting.name,
        setting.mapping,
        setting.type,
        setting.value,
        setting.valueItems,
        setting.dataType,
        setting.settingType,
        setting.settingOptionSet,
        setting.numericRoundType,
        setting.numericType,
        setting.numericCalculationMax,
        setting.numericMax,
        setting.numericMin,
        setting.override,
        data.edgeValue,
        setting.sortOrder,
        setting.show,
        setting.readonly,
        setting.modified,
        setting.previousValue
      );
    });
    // this.restoreProduct(new Product(
    //   data.attrs,
    //   data.type,
    //   data.handlePlacement,
    //   data.isBottom,
    //   data.settings,
    //   data.isFictional,
    //   data.isVisibleInDrawing,
    //   data.canBeSubproduct,
    //   data.additionalInfo,
    //   data.subCategory.subCategoryID,
    //   data.productID), layer);
  }

  static updateDataFromProduct(product: Product, data: any, wallIndex: number) {
    // find product
    if (!product.isFictional) {
      if (product.subProducts && product.subProducts.length > 0) {
        SubProductsUtil.updateSubProducts(product);
        product.subProducts.forEach((sub: Product) => {
          ProductUtils.updateDataFromProduct(sub, data, wallIndex);
        });
      }
      const row = data.productOrderRows.find(
        (x) => x.productOrderRowID === product.attrs.id
      );
      row.positionMarker = product.positionMarker;
      row.wallID = wallIndex;
      row.positionX = product.attrs.x;
      row.positionY = product.attrs.y;

      if (wallIndex !== null) {
        row.validated = product.isValid;
        if (product.isValid) {
          row.status = 'VALIDATED_DRAWING';
        } else {
          row.status = 'NEW';
        }
      } else {
        if (
          row.status !== 'VALIDATED_HK' &&
          row.status !== 'VALIDATED_HK_WITH_EXCEPTIONS'
        ) {
          row.status = 'NEW';
        }
      }

      if (!row.productOrderSettings) {
        return;
      }
      row.fictionalSettings = JSON.stringify({
        dekorlist: product.productSettings.HAS_DEKORLIST,
        sockel: product.productSettings.HAS_SOCKEL,
        newCabin: product.productSettings.NEW_CABIN,
      });

      row.productOrderSettings.forEach((orderSetting) => {
        const value = product.settings.find(
          (setting: Setting) => setting.id === orderSetting.settingID
        );
        if (value) {
          orderSetting.value = value.value;
          orderSetting.override = value.override;
          orderSetting.modified = value.modified;
          orderSetting.previousValue = value.previousValue;
        }
      });
    } else {
      const row = {
        wallID: wallIndex,
        positionX: product.attrs.x,
        positionY: product.attrs.y,
        validated: true,
        status: 'VALIDATED_DRAWING',
        fictional: true,
        fictionalID: product.fictionalID,
        fictionalSettings: JSON.stringify({
          width: product.productSettings.WIDTH,
          height: product.productSettings.HEIGHT,
          comments: product.productSettings.COMMENTS,
          hasSockel: product.productSettings.HAS_SOCKEL,
          hasDekorlist: product.productSettings.HAS_DEKORLIST,
        }),
      };

      data.productOrderRows.push(row);
    }
  }

  static updateDesign(product: Product, designs: any[]): void {
    designs?.forEach((design: any) => {
      if (design.designName === product.productSettings.DESIGN_OF_PRODUCT) {
        product.design = design;
      }
    });
  }


  static updateSettings(products: Product[], generalSettings: GeneralSettings) {
    if (!products || !generalSettings) {
      return;
    }


    products.forEach((product: Product) => {
      product.settings.forEach((pSetting: Setting) => {
        const newSetting = generalSettings.settings.find(
          (gSetting: Setting) => gSetting.id === pSetting.id
        );
        if (!newSetting) {
          return;
        }
        if (
          (!pSetting.modified && newSetting.value !== 0) ||
          pSetting.fictional
        ) {
          pSetting.value = newSetting.value;
        }
      });
      product.updateSettings();
    });
  }

  static updateOrderSettings(order: any, generalSettings: GeneralSettings) {
    if (!order || !order.productOrderSettings) {
      return;
    }
    order.productOrderSettings.forEach((oSetting: any) => {
      generalSettings.settings.forEach((gSetting: Setting) => {
        if (oSetting.settingID === gSetting.id) {
          if (gSetting.value) {
            oSetting.value = gSetting.value.toString();
          } else {
            oSetting.value = 0;
          }
        }
      });
    });
  }

  static checkValidity(product: Product): boolean {
    if (!product.settings) {
      return false;
    }
    return product.settings.find((setting) => {
      return (
        setting.value === null &&
        setting.type !== SettingTypesConsts.COMMENT &&
        setting.type !== SettingTypesConsts.FIXED &&
        setting.mapping &&
        setting.mapping.name !== Mapping.POSITIONNUMBER &&
        setting.show
      );
    })
      ? true
      : false;
  }




}
